<template>
  <div class="field_type--plain_text">
    <div class="value html" v-if="setup && setup.type == 'html'" >
      <div class="html-content" v-html="displayValue" v-if="displayValue != null"></div>
      <div class="null-content" v-else>--- {{ $t("label.fail_to_provide") }} ---</div>
    </div>
    <div class="value dp" v-else-if="setup && setup.decimalPoint">{{ value | toFixed(setup) | nullValue }}</div>
    <div class="value" v-else>{{ displayValue | nullValue }}</div>
    <div class="action">
      <div class="view-btn button" v-if="setup && setup.type == 'password'" @click="show">
        <fa :icon="['fas','eye-slash']" v-if="view"/>
        <fa :icon="['fas','eye']" v-else/>
      </div>
      <div class="copy-btn button" @click="copy" v-if="setup && setup.copy">
        <fa :icon="['far','clipboard']"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value","setup"],
  beforeMount() {
    if(this.setup?.type == "password") {
      this.displayValue = this.value.replace(/./g,"*");
    }else {
      this.displayValue = this.value;
    }
  },
  methods: {
    copy() {
      let el = document.createElement("input");
      el.value = this.value;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$notify({
        type: "success",
        msg: this.$t("message.copied_to_clipboard")
      });
    },
    show() {
      this.view = !this.view;
      if(!this.view) {
        this.displayValue = this.value.replace(/./g,"*");
      }else {
        this.displayValue = this.value;
      }
      
    }
  },
  filters: {
    toFixed(val, setup) {
      return val == null ? "-" : val.toFixed(setup.decimalPoint);
    }
  },
  data() {
    return {
      displayValue: "",
      view: false
    }
  }
}
</script>

<style lang="scss">
.field_type--plain_text{
  display: flex;
  flex-grow: 1;
  .value{
    /* flex-grow: 1; */
    padding: 3px 0;
    &.html{
      display: flex;
      justify-content: center;
      width: 100%;
      font-size: 13px;
      margin: 20px 10px;
      .html-content{
        background: white;
      }
      .null-content{
        padding: 10px 0;
        font-weight: bold;
      }
    }
  }
  .action{
    display: flex;
    align-items: center;
    .view-btn{
      margin-right: 10px;
    }
    .copy-btn{
      min-width: 25px;
      min-height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .button{
      cursor: pointer;
      user-select: none;
      transition: color .2s linear;
      color: #999;
      &:hover{
        color: black;
      }
    }
  }
}
</style>