<template>
  <div class="page_crud--edit">
    <FormBody
      :unikey="key"
      :structure="structure"
      :fields="setup.fields"
      :disabled-field="disabledField"
      :rule="setup.rule || {}"
      ref="form"
      :data="data"
      v-if="!load"
      type="edit"
    />
    <div class="action-btn">
      <EvButton class="back-btn" @click="$emit('close')">{{ $t("button.back") }}</EvButton>
      <EvButton class="submit-btn" @click="save">{{ $t("button.save") }}</EvButton>
    </div>
  </div>  
</template>

<script>
import FormBody from "@/components/modules/form/FormBody";

export default {
  props: ["id"],
  components: {
    FormBody
  },
  async created() {
    this.key = this.$route.params.key;
    this.obj_id = this.$route.params.id || this.id;
    Object.assign(this.setup, this.$setting.crud[this.key]);
    this.structure = this.setup.structure.edit || this.setup.structure.list.map(field=>[field]);
    if(this.setup?.blocked?.edit) {
      this.structure = this.structure.map(row=>{
        return row.filter(field=>{
          return !this.setup.blocked.edit.includes(field);
        });
      });
    }
    this.disabledField = this.setup?.disabled?.edit || [];
  },
  async beforeMount() {
    this.needLoading(true);
    this.load = true;
    try{
      this.data = (await this.$axios(`/${this.key}/${this.obj_id}`)).data;
    }catch(err) {
      console.log(err.response.status);
      this.$notify({
        // title: ,
        msg: `[${err.response.status}] ${this.$t("error.generic")}`,
        type: "error"
      });
    }
    this.load = false;
    await new Promise((resolve,reject)=>{
      setTimeout(()=>resolve(), 200);
    });
    this.$forceUpdate();
    this.needLoading(false);
  },
  methods: {
    async save() {
      let input = { ...this.$refs.form.input };
      Object.entries(this.setup.fields)
        .filter(([key,value])=>value.type=="object")
        .map(([key,value])=>{
          if(typeof input[key] == "undefined" || input[key] == null) return;
          console.log("this.setup.fields[key].props.value: ",this.setup.fields[key].props.value);
          input[key] = input[key][this.setup.fields[key].props.value]
        });
      console.log(input);
      await this.$refs.form.saveAll();
      this.needLoading(true);
      let setup = {};
      const formData = new FormData();
      if(this.setup.upload) {
        setup = {
          headers: {
            ...this.$axios.defaults.headers.common,
            "Content-Type": "multipart/form-data"
          },
        }
        Object.entries(input).map(([key,value])=>{
          if(value != null) {
            formData.append(key,value);
          }
        });
      }
      this.$axios.put(`${this.key}/${this.obj_id}`, this.setup.upload ? formData : input, setup).then(res=>{
        this.$notify({
          // title: ,
          msg: this.$t("message.data_update_success"),
          type: "success"
        });
        this.go(this.key);
        this.$emit('close');
        this.needLoading(false);
      }).catch(err=>{
        if(err?.response?.data?.errors) {
          Object.keys(err?.response?.data?.errors).map(field=>{
            this.$notify({
              title: `${err?.response?.status}`,
              msg: `[${this.tran(this.key, field)}] ${this.$t(`error.code.${err?.response?.data?.errors[field]}`)}`,
              type: "error"
            });
          });
          // this.$store.commit("control/setPopup", {show: true, messages: err.response.data.errors});
        }else {
          this.$notify({
            title: `${err.response.status}`,
            msg: "error",
            type: "error"
          });
        }
      }).then(()=>{
        this.needLoading(false);
      });
    }
  },
  data() {
    return {
      load: false,
      obj_id: null,
      key: "",
      setup: {},
      data: {},
      structure: [],
      disabledField: []
    }
  }
}
</script>

<style lang="scss" scoped>
.page_crud--edit{
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  .action-btn{
    display: flex;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: space-between;
  }
}
</style>