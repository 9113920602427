<template>
  <div class="widget--toggle" :style="`width:${setup?.style?.width}px; height:${setup?.style?.height}px;`">
    <button 
      v-if="setup?.component == 'button'"
      class="module--toggle_button"
      :style="`
        background-color:${setup?.style?.color};
      `"
      :disabled="setup?.disabled || state"
      @click="toggle"
    >{{ label }}</button>
    <div v-else class="inner-wrap" :class="state ? 'on' : 'off'" @click="toggle">
      <div class="button"></div>
    </div>
    <!-- confirm popup -->
    <Popup class="confirm--popup" ref="confirm_popup">
      <div class="popup-inner-wrap">
        <div class="message" v-html="$t('message.are_u_sure')"></div>
        <div class="action-btn">
          <div class="button cancel" @click="close">{{ $t("button.cancel") }}</div>
          <div class="button confirm" @click="confirm">{{ $t("button.confirm") }}</div>
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
export default {
  props: ["value", "setup", "label"],
  beforeMount() {
    if (typeof this.value != "undefined") this.state = this.value
  },
  methods: {
    toggle() {
      if (this?.setup?.needPopup) {
        this.$refs.confirm_popup.show();
      } else {
        this.state = !this.state;
        this.$emit('input', this.state);
      }
    },
    async close() {
      this.$refs.confirm_popup.close();
      this.state = false
      this.$emit('input', this.state);
    },
    async confirm() {
      this.$refs.confirm_popup.close();
      if (this.value)
        this.state = false
      else
        this.state = true
      this.$emit('input', this.state);
    }
  },
  data() {
    return {
      state: false
    }
  }
}
</script>

<style lang="scss">
.widget--toggle {
  display: flex;
  .module--toggle_button{
  flex: 1;
  background: #4379de;
  border: 0px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity .2s linear;
  width: 100px;
  height: 40px;
    &:disabled{
      background: #0547c0;
      opacity: .4;
      cursor: default;
    }
  }
  .inner-wrap {
    height: 15px;
    width: 35px;
    display: flex;
    padding: 4.5px 5px;
    background: #ccc;
    border-radius: 10px;
    transition: all .2s ease-out;
    cursor: pointer;

    .button {
      width: 20px;
      height: 100%;
      background: white;
      border-radius: 6px;
      transition: all .2s ease-out;
    }

    &.on {
      background: #04AA6D;

      .button {
        transform: translateX(15px);
      }


    }
  }

  .confirm--popup {
    b {
      margin: 0 5px;
    }

    .inner-wrap {
      width: 350px;
      height: 120px;
      background: white;
      border-radius: 5px;

      .popup-content {
        flex-grow: 1;
      }

      .popup-inner-wrap {
        // height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .message {
          flex-grow: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
        }

        .action-btn {
          display: flex;
          padding: 10px 10px;
          gap: 10px;

          .button {
            border-radius: 5px;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            color: white;
            user-select: none;
            cursor: pointer;

            &.confirm {
              background: #aaa;
            }

            &.cancel {
              background: rgb(231, 0, 0);
            }
          }
        }
      }
    }

    .close-btn {
      display: none;
    }
  }

  .confirm--popup.show {
    .overlay {
      display: unset;
    }
  }
}
</style>