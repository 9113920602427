<template>
  <div class="widget--form_form_body" :class="{ 'view-only': view }">
    <div class="inner-wrap" v-if="!load">
      <div class="row" v-for="(row,i) in rows" :key="i">
        <div class="field" :class="[`type--${field.type}`, { 'error--field': errors[field.key] }]" v-for="(field,j) in row" :key="j" v-show="field.type != 'action-btn'">
          <div class="label" v-if="isShowLabel(field)" v-required="isFieldRequired(field.key)">{{ tran(unikey, field.key) }}</div>
          <div class="value">
            <div class="prefix" v-if="view && field.props && field.props.prefix">{{ field.props.prefix }}</div>
            <Component
              :ref="`component--${field.key}`"
              :class="{'disabled--component': disabledField.includes(field.key)}"
              :is="getMappedComponentByType(field)"
              :data="data"
              :view="view"
              :value="row[field.key]"
              v-model="input[field.source ? field.source : field.key]"
              @input="clearError(field.key)"
              :option="options[field.key]"
              :disabled="disabledField.includes(field.key)"
              :error="errors[field.key]"
              :placeholder="field.props?.placeholder"
              @updateAll="updateAll"
              :id="`field--${field.key}`"
              :setup="field.props"
              :label="tran(unikey, field.key)"/>
            <div class="suffix" v-if="view && field.props && field.props.suffix">{{ field.props.suffix }}</div>
          </div>
          <div class="error" v-if="!view">{{ errors[field.key] ? $t(`validation.${errors[field.key]}`) : "" }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// write
import InputBox from "@/components/modules/form/InputBox";
import Selection from "@/components/modules/form/Selection";
import DateInputBox from "@/components/modules/form/DateInputBox";
import EvCheckbox from "@/components/modules/form/EvCheckbox";
// view
import Boolean from "@/components/widgets/Datatable/field-types/Boolean";
import ObjectEl from "@/components/widgets/Datatable/field-types/Object";
import Datetime from "@/components/widgets/Datatable/field-types/Datetime";
import PlainText from "@/components/widgets/Datatable/field-types/PlainText";
import Status from "@/components/widgets/Datatable/field-types/Status";
import Toggle from '../../widgets/Toggle.vue';

export default {
  props: {
    unikey: String,
    type: String,
    data: Object,
    fields: Object,
    structure: {
      type: Array,
      default() {
        return []
      }
    },
    disabledField: {
      type: Array,
      default() {
        return [];
      }
    },
    rule: {
      type: Object,
      default() {
        return {};
      }
    },
    view: {
      default: false
    }
  },
  async beforeMount() {
    this.load = true;
    this.initStructure();
    if(!this.view) await this.retrieveOptions();
    // Object.entries(this.data)
    if(this.data != null && Object.keys(this.data).length > 0) {
      this.input = this.data;
    }
    if(this.type == "create") {
      Object.keys(this.fields).map(key=>{
        console.log("field: ", key);
        if(typeof this.fields[key].default == "undefined") return;
        this.input[key] = this.fields[key].default;
      });
    }
    this.load = false;
  },
  methods: {
    initStructure() {
      this.structure.map((row,i)=>{
        this.rows[i] = [];
          row.map((field,j)=>{
          let current = this.fields[field];
          let locale = this.fields[field.substring(0,field.length-3)];
          let obj = { key: field, type: "text" };
          if(current || locale) {
            this.rows[i].push({
              ...obj,
              ...current,
              ...locale
            });
          }else {
            this.rows[i].push(obj);
          }
        })
      });
    },
    updateAll(e) {
      this.input = {
        ...this.input,
        ...e
      };
      this.key++;
    },
    isShowLabel(obj) {
      if(typeof obj.props == "undefined") return true;
      if(typeof obj.props.label == "undefined") return true;
      return obj.props.label;
    },
    async retrieveOptions() {
      let option_keys = Object.entries(this.fields)
                          .filter(([key,value])=>value.type=="object")
                          .map(([key,value])=>key);
      // no object field, quit instantly
      if(option_keys.length==0) return;
      // api call
      this.options = (await this.$axios.get(`options`, {
        params: {
          opts: option_keys,
          key: this.unikey
        }
      })).data.response.options;
      // Object.entries(options).map(([key,opts])=>{
      //   let opt_props = this.fields[key].props;
      //   console.log("opt props: ", opt_props);
      //   opts.map(opt=>{
      //     if(!this.options.hasOwnProperty(key)) {
      //       this.options[key] = [];
      //     }
      //     this.options[key].push({
      //       label: opt[opt_props.label],
      //       value: opt[opt_props.value],
      //     });
      //   });
      // });
    },
    getMappedComponentByType(field) {
      if(field.type == "component") return field.props.component;
      if(this.view) {
        return this.viewTypeMap[field.type];
      }else {
        return this.dataTypeMap[field.type];
      }
    },
    saveAll() {
      console.log("save all")
      return new Promise(async (resolve,reject)=>{
        // validation
        var valid = true;
        this.rule?.required?.map(field=>{
          // required
          if(this.input[field] == null || this.input[field].length === 0) {
            valid = false;
            this.errors[field] = "REQUIRED";
          }
          else if(this?.fields[field] && this?.fields[field]?.form_checking){
            var fun = this?.fields[field]?.form_checking;
            this.errors[field]=this.$form_checking[fun](this?.fields[field],this.input,field);
            if(this.errors[field].length > 0){
              valid = false;
            }
          }
        });
        this.rule?.validation?.map(field=>{
          if (Array.isArray(field.value)) {
            const validation = (e) => {
              var result = true;
              if (result && e?.preCondition) {
                result = eval(e?.preCondition)
              }
              if (result && e?.regex) {
                result = this.input[field.key].match(e?.regex)
              }
              if (result && e?.condition) {
                result = eval(e.condition)
              }
              return result
            }
            valid = field.value.some(validation)
            if (!valid) {
              this.errors[field.key] = "FAILEDVALIDATION";
            }
          }

        });        
        console.log("valid: ", valid);
        this.$forceUpdate();
        if(!valid) {
          console.log("invalid");
          window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
          // scroll to field
          let keys = Object.keys(this.errors);
          if(keys > 0) {
            let el = document.querySelector(`#field--${keys[0]}`);
            if(typeof el != "undefined") {
              el.scrollIntoView({
                behavior: 'smooth',
                block: "center"
              });
            }
          }
          return valid;
        }
        let components = [];
        this.rows.flatMap(field=>field.flatMap(async field=>{
          // console.log("field: ",field);
          if(field?.type == "component") {
            console.log("component: ", this.$refs[`component--${field.key}`]);
            if(this.$refs[`component--${field.key}`] && typeof this.$refs[`component--${field.key}`][0].save == "function") {
              components.push(this.$refs[`component--${field.key}`][0]);
            }
          }
        }));
        this.needLoading(true);
        for(let i=0;i<components.length;i++) {
          await components[i].save();
        }
        this.needLoading(false);
        resolve();
      });
    },
    clearError(field) {
      if(!this.isFieldRequired(field)) return;
      this.errors[field] = "";
    },
    isFieldRequired(key) {
      return this.rule?.required?.includes(key) || false;
    }
  },
  data() {
    return {
      load: false,
      key: 0,
      rows: [],
      errors: {},
      input: {},
      options: {},
      dataTypeMap: {
        text: InputBox,
        object: Selection,
        datetime: DateInputBox,
        boolean: Toggle,
      },
      viewTypeMap: {
        boolean: Boolean,
        object: ObjectEl,
        object2: ObjectEl,
        datetime: Datetime,
        text: PlainText,
        status: Status
      }
    }
  }
}
</script>

<style lang="scss">
.widget--form_form_body{
  flex-grow: 1;
  width: 100%;
  .row{
    display: flex;
    align-items: flex-end;
    margin-bottom: 2px;
    .field{
      flex-grow: 1;
      width: 100%;
      & > .label{
        font-size: 14px;
        margin-bottom: 5px;
      }
      & > .error{
        min-height: 16px;
        font-size: 12px;
        color: red;
      }
      &:not(:first-child){
        margin-left: 5px;
      }
      .widget--form_selection{
        .inner-wrap{
          width: 100%;
          height: 35px;
          .option{
            height: 35px;
          }
          .option-wrapper{
            top: 40px;
          }
        }
      }
      &.type--boolean{
        display: flex;
        align-items: center;
        margin-bottom: 7px;
        .value{
          flex: 1;
          order: 1;
        }
        .label{
          // order: 2;
          margin-right: 10px;
          margin-bottom: 0px;
        }
      }
    }
  }
  &.view-only{
    word-break: break-word;
    .row{
      margin-bottom: 10px;
    }
    .field{
      &.type--boolean{
        border-radius: 3px;
        // background: #ddd;
        // padding: 0 10px;
        .label{
          order: 1;
          display: flex;
          align-items: center;
          margin-right: 10px;
        }
        .value{
          background: white;
        }
      }
    }
  }
  .prefix{
    margin-right: 2px;
  }
  .suffix{
    margin-left: 2px;
  }
}
</style>